import React from 'react';
import '../../style/content.css';
import {ContentBlock, ContentColumn, ContentRow, ImgMedium, ImgSmall, Title} from "../GeneralComponents";
import image_paintbrush from "../../resources/img_paintbrush.jpg";
import { Element } from 'react-scroll';

const Service = () => {
    return (
        <>
            <Element id="service">
                <ContentBlock>
                    <Title>
                        Prestations
                    </Title>
                    <ContentRow>
                        <ContentColumn>
                            <p>
                                Je me déplace pour tout évènement tels que manifestations, anniversaires, fêtes privées, marchés… et
                                propose les prestations suivantes :
                            </p>
                            <ul>
                                <li>Maquillage artistique</li>
                                <li>Tatouages temporaires à paillettes</li>
                                <li>Belly painting pour future maman</li>
                            </ul>
                        </ContentColumn>
                        <ImgSmall src={image_paintbrush} alt="Paintbrush"/>
                    </ContentRow>
                </ContentBlock>
            </Element>
        </>
    );
}

export default Service