import React from "react";
import {Navbar} from './components/NavbarElement';
import Service from './components/sections/Service';
import Portfolio from './components/sections/Portfolio';
import About from './components/sections/About';
import ContactFooter from "./components/ContactFooter";
import Equipement from "./components/sections/Equipement";
import Grimagine from "./components/sections/Grimagine";

import './style/App.css';

function App() {

    return (
        <>
            <Navbar/>
            <Grimagine/>
            <About/>
            <Service/>
            <Portfolio/>
            <Equipement/>
            <ContactFooter/>
        </>
    );

}

export default App;
