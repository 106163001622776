import {ContentBlock, ContentColumn, ImgSmall, Title} from "../GeneralComponents";
import React from "react";
import equipement from "../../resources/equipement.jpg";

const Equipement = () => {
    return (
        <>
            <ContentBlock>
                <Title>
                    Matériel
                </Title>
                <ContentColumn>
                    <p>
                        Toutes les peintures utilisées sont approuvées par la FDA et UE.
                        Les couleurs sont VEGAN, sans gluten, sans paraben et sans parfum.
                        Elles ne sont pas fabriquées à partir de biproduits animaux, ni testées sur des animaux.
                    </p>
                    <ImgSmall src={equipement} alt="Paintbrush"/>
                    <p>Les photos de présentations ont été réalisées par Instant images</p>
                    <a href="https://www.instantsimages.ch/">https://www.instantsimages.ch/</a>
                </ContentColumn>
            </ContentBlock>
        </>
    );
}

export default Equipement;
