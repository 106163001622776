import React from 'react';
import {NoMarginContentBlock, Title} from "../GeneralComponents";
import ImageSlideshow from "../ImageSlideshow";
import { Element } from 'react-scroll';

import '../../style/content.css';

import i1 from "../../resources/portfolio/portfolio1.jpg";
import i2 from "../../resources/portfolio/portfolio2.jpg";
import i3 from "../../resources/portfolio/portfolio3.jpg";
import i4 from "../../resources/portfolio/portfolio4.jpg";
import i5 from "../../resources/portfolio/portfolio5.jpg";
import i6 from "../../resources/portfolio/portfolio6.jpg";
import i7 from "../../resources/portfolio/portfolio7.jpg";
import i8 from "../../resources/portfolio/portfolio8.jpg";
import i9 from "../../resources/portfolio/portfolio9.jpg";

import i10 from "../../resources/portfolio/portfolio10.jpg";
import i11 from "../../resources/portfolio/portfolio11.jpg";
import i12 from "../../resources/portfolio/portfolio12.jpg";
import i13 from "../../resources/portfolio/portfolio13.jpg";
import i14 from "../../resources/portfolio/portfolio14.jpg";
import i15 from "../../resources/portfolio/portfolio15.jpg";
import i16 from "../../resources/portfolio/portfolio16.jpg";


const manifestiations_pics = [
    i10, i11, i12, i13, i14, i15, i16
]

const intfsp_pics = [
    i1, i2, i3, i4, i5, i6, i7, i8, i9
]

let displayedImages = manifestiations_pics;

const changeImageList = state => {
    if (displayedImages === manifestiations_pics) {
        displayedImages = intfsp_pics
    } else {
        displayedImages = manifestiations_pics
    }
}

const Portfolio = () => {
    return (
        <>
            <Element id="portfolio">
                <NoMarginContentBlock>
                    <Title style={{marginLeft: '15%', marginRight: '15%'}}>
                        Portfolio
                    </Title>
                    <ImageSlideshow imageList={displayedImages}/>
                </NoMarginContentBlock>
            </Element>
        </>
    );
}

export default Portfolio
