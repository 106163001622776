import React from 'react';
import {ContentBlock, ContentColumn, ContentRow, ImgMedium, ImgPortrait, Quote, Title} from "../GeneralComponents";
import { Element } from 'react-scroll';

import '../../style/content.css';

import color_palette from "../../resources/color_palette.jpg";
import portrait_inside from "../../resources/portrait_inside.jpg";

const About = () => {
    return (
        <>
            <Element id="about">
                <ContentBlock>
                    <Title>
                        A propos
                    </Title>
                    <ContentRow>
                        <ContentColumn>
                            <p className="text">
                                Passionnée depuis longtemps par le dessin et la peinture, j’ai toujours grimé mes enfants…
                                avec du matériel de grandes surfaces. Un jour j’ai eu l’occasion de rencontrer une personne
                                qui utilisait du matériel professionnel et des techniques que je ne connaissais pas.
                            </p>
                            <Quote>
                                "Ce que je préfère avant tout, c’est le sourire de l’enfant lorsqu’il se découvre
                                dans le miroir"
                            </Quote>
                        </ContentColumn>
                        <ImgPortrait src={portrait_inside} alt="Portrait à l'intérieur"/>
                    </ContentRow>
                </ContentBlock>

                <ContentBlock>
                    <Title>
                        Ma formation
                    </Title>
                    <ContentColumn>
                        <p>
                            J’ai tout de suite eu envie de me former et j’ai suivi plusieurs cours, puis des Workshop et des
                            Masterclasses d’artistes renommés. Puis en 2021 j’ai obtenu mon diplôme de face painting à
                            l’INTFSP
                            – Ecole Internationale de Face Painting. Depuis je continue avec plaisir à me former pour
                            parfaire
                            mes connaissances et améliorer ma technique.
                        </p>
                        <ImgMedium src={color_palette} alt="Palette de couleures"/>
                    </ContentColumn>
                </ContentBlock>
            </Element>
        </>
    );
}

export default About
