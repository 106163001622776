import React, {useState} from "react";
import styled from 'styled-components';
import {Link as ScrollLink} from "react-scroll";
import {FaBars} from 'react-icons/fa';
import ColoredSnowParticles from './ColoredSnowParticles';

import logo from "../resources/grimagine.jpg";

export function Navbar() {
    const [isdropdownmenutoggled, setDropdownMenuToggled] = useState(false);

    function toggleDropdownMenu() {
        setDropdownMenuToggled(!isdropdownmenutoggled)
    }

    return (
        <>
            <Nav isdropdownmenutoggled={isdropdownmenutoggled}>
                <ColoredSnowParticles/>
                <Bars toggleDropdownMenu={toggleDropdownMenu} isdropdownmenutoggled={isdropdownmenutoggled}/>

                <NavMenu isdropdownmenutoggled={isdropdownmenutoggled}>
                    <NavLink to='about' smooth={true} duration={500}>
                        A propos
                    </NavLink>
                    <NavLink to='service' smooth={true} duration={500}>
                        Prestations
                    </NavLink>
                    <NavLink to='portfolio' smooth={true} duration={500}>
                        Portfolio
                    </NavLink>
                    <NavLink to='contact' smooth={true} duration={500}>
                        Contact
                    </NavLink>
                </NavMenu>

                <Logo logo={logo}/>
            </Nav>
        </>
    );
}

const Logo = (props) => {
    return (
        <StyledLogo logo={props.logo}/>
    )
}

const StyledLogo = styled.div`
    display: block;
    position: absolute;
    right: 5%;
    height: 90px;
    width: 90px;
    background-image: url(${({logo}) => logo});
    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (max-width: 768px) {
        height: 60px;
        width: 60px;
    }
`

const Bars = (props) => {
    return (
        <StyledBars isdropdownmenutoggled={props.isDropdownMenuToggled} onClick={props.toggleDropdownMenu}/>
    )
}

const StyledBars = styled(FaBars)`
    display: none;
    color: #808080;
    @media screen and (max-width: 768px) {
        display: block;
        position: absolute;
        left: 10%;
        font-size: 1.8rem;
        cursor: pointer;
        transition: transform 1s;
        transform: ${props => props.isdropdownmenutoggled ? "rotate(90deg)" : "none"};
    }
`;

export const Nav = styled.nav`
    position: sticky;
    top: 0;
    width: 100%;
    overflow: hidden;

    background: #010101;
    height: 85px;
    display: flex;
    justify-content: space-between;
    padding: 1%;
    @media screen and (max-width: 768px) {
        align-items: center;
        justify-content: center;
        transition: height ${props => props.isdropdownmenutoggled ? ".6s" : ".7s"};
        transition-delay: ${props => props.isdropdownmenutoggled ? ".1s" : ".3s"};
        height: ${props => props.isdropdownmenutoggled ? "200px" : "75px"};
    }
`;

export const NavLink = styled(ScrollLink)`
    color: #808080;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 2% 6%;
    height: 100%;
    white-space: nowrap;
    cursor: pointer;
    border-radius: 3px;
    z-index: 100;

    &.active {
        color: aliceblue;
    }

    &:hover {
        //background: #320101;
        color: aliceblue;
        transition: background 1s;
    }

    @media screen and (max-width: 768px) {
        padding: 15% 25%;
    }

`;

export const NavMenu = styled.div`
    display: flex;
    align-items: center;
    margin-right: -24px;
    @media screen and (max-width: 768px) {
        transition: visibility .4s linear, opacity .4s linear;
        transition-delay: ${props => props.isdropdownmenutoggled ? ".2s" : 0};
        opacity: ${props => props.isdropdownmenutoggled ? 1 : 0};
        visibility: ${props => props.isdropdownmenutoggled ? "visible" : "hidden"};
        flex-direction: column;
        margin-right: 0;
    }
`;
