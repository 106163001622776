import styled from "styled-components";

export const Title = styled.div`
    padding: 2%;
    font-size: 25pt;
    border-bottom: #282c34 1px solid;
    margin-top: 5%;
`

export const Quote = styled.p`
    vertical-align: middle;
    font-size: 18pt;
    font-family: "Times New Roman", sans-serif;
`

export const NoMarginContentBlock = styled.div`
    text-align: center;
    align-items: center;
    justify-content: center;
`

export const ContentBlock = styled.div`
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-right: 15%;
    margin-left: 15%;
    padding: 2%;
`

export const ContentColumn = styled.div`
    margin: 1% 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 768px) {
        margin: 1% 2%;
        display: flex;
        flex-direction: column;
    }
`

export const ContentRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 768px) {
        margin: 1% 2%;
        display: flex;
        flex-direction: column;
    }
`

export const ImgMedium = styled.img`
    padding: 2%;
    height: auto;
    width: 45vw;
    
    @media screen and (max-width: 768px) {
        height: auto;
        width: 90vw;
    }
`

export const ImgSmall = styled.img`
    padding: 2%;
    height: auto;
    width: 35vw;
    
    @media screen and (max-width: 768px) {
        height: auto;
        width: 90vw;
    }
`

export const ImgPortrait = styled.img`
    padding: 2%;
    height: 65vh;
    width: auto;

    @media screen and (max-width: 768px) {
        height: auto;
        width: 50vw;
    }
`
