import {
    ContentBlock,
    ContentColumn,
    ContentRow,
    ImgPortrait,
    Quote,
    Title
} from "../GeneralComponents";
import portrait_outside from "../../resources/portrait_outside.jpg";
import React from "react";

const Grimagine = () => {
    return (
        <>
            <ContentBlock>
                <Title>
                    Grimagine
                </Title>
                <ContentRow>
                    <ImgPortrait src={portrait_outside} alt="Portrait dehors"/>
                    <ContentColumn>
                        <Quote>
                            Bienvenue dans mon univers de couleurs et de paillettes
                        </Quote>
                        <p>
                            Je m'appelle Sylvie Pasquier et réalise des grimages, tatouages temporaires et belly painting pour futures mamans.<br/>
                            Basée à Bulle, en Gruyère, je me déplace volontiers dans tout le canton de Fribourg et
                            la Suisse romande.
                        </p>
                    </ContentColumn>
                </ContentRow>

            </ContentBlock>
        </>
    );
}

export default Grimagine
