import React from 'react';
import { Slide } from 'react-slideshow-image';
import {ImgPortrait} from "./GeneralComponents";

import 'react-slideshow-image/dist/styles.css'

const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    width: '100%',
    margin: '2%',
}

const responsiveSettings = [
    {
        breakpoint: 800,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 1
        }
    },
    {
        breakpoint: 500,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        }
    }
];

const ImageSlideshow = ({imageList}) => {
    return (
        <div className="slide-container">
            <Slide responsive={responsiveSettings}>
                {imageList.map((slideImage, index)=> (
                    <div key={index} style={{...divStyle}}>
                        <ImgPortrait alt="Slide Image" src={slideImage}/>
                    </div>
                ))}
            </Slide>
        </div>
    )
}

export default ImageSlideshow
